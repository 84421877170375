export default {
  //breakpoints: ["40em", "52em", "64em"],
  breakpoints: ["36em", "47em", "62em", "75em"], //bootsrap 576px, 768px, 992px, 1200px
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fonts: {
    body: '"Asap", sans-serif',
    heading: "inherit",
    monospace: "Menlo, monospace",
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  colors: {
    text: "#000",
    mutedText: "#363537",
    background: "#fff",
    primary: "#ED4949", //red salsa
    primaryBG: "#fdecec", //lavender blush (.35 lighter)
    // secondary: '#30c', //off red?
    //primary: "#034078", // Indigo Dye
    // primary: "#1282A2", // CG Blue
    //primary: "#9CC5A1", // Eton Blue
    //primary: "#F06543", // Orange Soda
    //primary: "#BEB7DF", // Maximum Blue Purple
    // primary: "#23967F", // Paolo Veronese Green
    secondary: "#30c", //
    muted: "#f6f6f6",
    modes: {
      blue: {
        text: "#000",
        mutedText: "#363537",
        background: "#fff",
        primary: "#034078", // Indigo Dye
        primaryBG: "#afd7fd", //Baby Blue Eyes (.55 lighter about)
      },
    },
  },
  text: {
    heading: {
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
    },
  },
  buttons: {
    primary: {
      color: "background",
      cursor: "pointer",
      bg: "primary",
      "&:hover": {
        bg: "text",
      },
    },
    secondary: {
      color: "background",
      cursor: "pointer",
      bg: "#444",
      "&:hover": {
        bg: "text",
      },
    },
  },
  styles: {
    root: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
      a: {
        color: "primary",
        "&:hover": {
          textDecoration: "none",
        },
      },
    },
    h1: {
      variant: "text.heading",
      fontSize: 5,
    },
    h2: {
      variant: "text.heading",
      fontSize: 4,
    },
    h3: {
      variant: "text.heading",
      fontSize: 3,
    },
    h4: {
      variant: "text.heading",
      fontSize: 2,
    },
    h5: {
      variant: "text.heading",
      fontSize: 1,
    },
    h6: {
      variant: "text.heading",
      fontSize: 0,
    },
    pre: {
      fontFamily: "monospace",
      overflowX: "auto",
      code: {
        color: "inherit",
      },
    },
    code: {
      fontFamily: "monospace",
      fontSize: "inherit",
    },
    table: {
      width: "100%",
      borderCollapse: "separate",
      borderSpacing: 0,
    },
    th: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
    td: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
  },
}
